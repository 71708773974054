<template>
  <div>
    <a-modal
      centered
      :closable="false"
      :footer="false"
      :visible="visible"
      :mask-closable="false"
    >
      <img src="../../assets/images/print.png" alt="print-img" />
      <p class="font-xl text-c bold">{{ process }}/{{ total }}</p>
      <p class="font-xl text-c bold">请勿离开！</p>
      <p class="font-xl text-c bold">门票正在出票中...</p>
    </a-modal>
    <!-- 查询结果弹窗 -->
    <Result
      :vis.sync="resultDialog"
      :text="resultText"
      btn-text="返回首页"
      @customAction="resultOK"
    ></Result>
  </div>
</template>
<script>
import Result from "./result.vue";
import { mapGetters } from "vuex";
export default {
  name: "PrintTicket",
  components: {
    Result
  },
  model: {
    prop: "visible",
    event: "close"
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 门票弹窗打印完后，是否需要显示返回首页按钮
    needBackHome: {
      type: Boolean,
      default: true
    },
    // 点击返回首页按钮时，是否清空购物车
    clearCart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      process: 0,
      total: 0,
      resultDialog: false,
      resultText: "打印成功，点击按钮返回首页"
    };
  },
  computed: {
    ...mapGetters("setting", ["menuData"])
  },
  created() {
    this.checkTicketType();
    if (this.$store.state.common.printingRequired) {
      // 提供方法供java调用，并获取其传入参数
      window.printProgress = this.printProgress;
    }
  },
  methods: {
    printProgress(params) {
      console.log(params, "---*----打印进度回传数据-");
      if (params) {
        const arr = params.split(",");
        this.total = arr[0];
        this.process = arr[1];
        if (this.process >= this.total) {
          setTimeout(() => {
            this.$emit("close", false); // 关闭打印进度弹窗
            setTimeout(() => {
              if (this.needBackHome) {
                console.log("打印弹窗里++++++++返回首页弹窗");
                this.resultDialog = true;
              }
            }, 1000);
            this.checkTicketType(); // 购买的门票，若有腕带票，提示去窗口取票
          }, 1000);
        }
      }
      // console.log("java调用printProgress方法传入的数据:", params);
    },
    // 结果提示页，关闭或点击确定
    resultOK() {
      this.clearCart && this.$store.dispatch("order/setCartList", {});
      const url = this.menuData[0].url;
      this.$router.replace(url);
    },
    // 查看购买的票，是否有腕带票
    checkTicketType() {
      const data = this.$store.state.order.cartList;
      if (data.productItemList && data.productItemList.length) {
        data.productItemList.forEach(item => {
          if (item.voucherType > 0) {
            this.$speak("购买的门票里有腕带票，请去窗口取票");
            this.resultText = "买的产品中有腕带票，请去窗口取票";
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped></style>
